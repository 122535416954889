export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <video autoplay="true" muted loop playsinline id="videoBackground">
        <source src="../img/service-bg.mp4" type="video/mp4" />
          Your browser does not support the video tag.
      </video>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Services</h2>
          <p>
            We provide high-quality software development services.
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-md-4'>
                {' '}
                <i className={d.icon}></i>
                <div className='service-desc'>
                  <h3>{d.name}</h3>
                  <p>{d.text}</p>
                </div>
              </div>
            ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
