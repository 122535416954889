export const Testimonials = (props) => {
  return (
    <div id='testimonials'>
      <video autoplay="true" muted loop playsinline id="videoBackground">
        <source src="../img/company.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className='container' style={{ padding: '50px 0' }}>
        <div className='section-title text-center'>
          <h2>What our clients say</h2>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-md-4'>
                <div className='testimonial'>
                  <div className='testimonial-image'>
                    {' '}
                    <img src={d.img} alt='' />{' '}
                  </div>
                  <div className='testimonial-content'>
                    <p>"{d.text}"</p>
                    <div className='testimonial-meta'> - {d.name} </div>
                  </div>
                </div>
              </div>
            ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
